import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Body from './components/Body/Body';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        // Strict mode was originally inside the Body and it was causing the everything to run twice all functions
        // <React.StrictMode>
                <Body />
        //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
