import React from "react";
import "./photoBy.css";

class PhotoBy extends React.Component {
    render() {
        // console.log(this.props);
        return (
            <div className="PhotoBy">
                {/* <a style={{zIndex: "1"}} href="https://unsplash.com/@dieter_muenchen?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Dieter K</a> on <a href="https://unsplash.com/photos/M4G5BSUo1p4?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a> */}
            </div>
        );
    }

};

export default PhotoBy;