import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import './Body.css';
//import NavBar from '../customComponents/navBar/NavBar';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import navJson from './bodyJson/bodyNav.json';
import PhotoBy from './photoBy';
const node_Env = process.env.REACT_APP_ENV;
const searchURL = node_Env === 'dev' ? 'http://' + window.location.hostname + ':3002/api/' : '/api/';
// console.log(searchURL);

//beginning of body class component
class Body extends React.Component {
    //creation of constructor
    constructor() {
        //constructor needs super
        super();
        //creation of states that will be used later
        this.state = {
            validated: false,
            currentPage: "",
            endpoints: navJson.url,
            searchExecuted: false,
        }

        //validation function so that it is kept seperate from other code that may change later.
        this.validateSession = async () => {
            try {
                //run fetch for validation sending cookies to /Validate API
                const resp = await fetch(searchURL + 'Validate', {
                    method: 'POST',
                    mode: 'cors',
                    //always include credentials where authentication is needed
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                //check response status and only validate with status 200
                if (resp.status === 200) {
                    //set state to valid for valid sessions (default false)
                    this.setState({
                        validated: true
                    });
                    // console.log('Valid Session!');
                } else {
                    //redirect user to /Login if session is not valid and log msg
                    window.location.replace('/Login');
                    // console.log('Invalid Session!');
                }
                // alert(resp.status);
            } catch (error) {
                //if there is any error with try then default to /Login page and log error
                window.location.replace('/Login');
                //may want alert above re-direct so users can submit errors later
                console.log(error);
            }
        }

    }

    //once the component is mounted then run code
    componentDidMount() {

        //assign state a value
        this.setState({
            currentPage: window.location.pathname.toLowerCase()
        })
        //create variable of validate session
        const validateSession = async () => {
            //URL value assigned to variable to be used in switch
            let currLocation = window.location.pathname.toLocaleLowerCase()
            //switch to determine if validation is needed for certain endpoints
            // console.log(currLocation)
            switch (currLocation) {
                case ('/home'):
                case ('/login'):
                case ('/about'):
                case ('/contact'):
                case ('/register'):
                case ('/tickets'):
                case ('/ticket/success'):
                    // print message to console for endpoints not needing authentication
                    // console.log("Authentication not needed: ", currLocation);
                    break;
                default:
                    // calling these here because only certain pages need validated on
                    // and importClients will only work when a valid user is present
                    // this.importClients();
                    this.validateSession();
            }
        };
        if (!this.state.searchExecuted) {
            validateSession();
            this.setState({ searchExecuted: true });
        }

    }

    //return of router
    render() {
        return (
            <>
                <PhotoBy />
                {/* open of the router with base location of root */}
                <Router basename={'/'}>
                    {/* navbar was been placed here to display across all loaded pages, will have to change logged in navbar versus logged out nav bar*/}
                    {/*(this.state.currentPage === '/home' || this.state.currentPage === '/about' || this.state.currentPage === '/login' || this.state.currentPage === '/contact' || this.state.currentPage === '/tickets') && <NavBar />*/}
                    {/* suspense is whats going to be displayed until the current page loads */}
                    <Suspense fallback={<div>Loading...</div>}>
                        {/* router contains all the routes that are to be loaded / going to be loaded */}
                        <Routes>
                            {/* this route is specifically designed to re-direct to /home endpoint from "/" root of project
                         this can later be used to redirect to the login page and from the login page
                                                                Navigate is the part which initiated the re-direct to /Home */}
                            <Route exact path="/" key="AppHome" element={<Navigate replace to="/Admin/Manage" />} />
                            {
                                //navJson is the json that contains all the endpoints that we are wanting to render
                                //Data is the data that is in the JSON, Key is an auto generated Key for each element being generated
                                //The Key is usually required with all mapped elements
                                // console.log(navJson.url)
                                this.state.endpoints.map((data, key) => {
                                    //mapping the JSON path to the router path, key = key The element is the JS file that is going to be rendered.
                                    //          We are lazy importing the path because it will not allow regular import while loading,  and allows the URL to be loaded
                                    //          dynamically
                                    //check if the state.validated and check current page match /Home (page to be validated)
                                    // if (this.state.currentPage === '/home' && !this.state.validated) {
                                    //     // Don't display /home if not authenticated
                                    //     return null;
                                    // }
                                    if (this.state.currentPage === '/admin' && !this.state.validated) {
                                        // Don't display /admin if not authenticated
                                        return null;
                                    }
                                    // console.log(data['routerPath']);
                                    // Render the page
                                    return <Route exact path={data['routerPath']} key={key} element={React.createElement(lazy(() => import("../" + data.filePath)))} />;
                                })
                            }
                        </Routes>
                    </Suspense>
                </Router>
            </>
        );
    }
}
export default Body;